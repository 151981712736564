.modal-timesOut-body,
.modal-timesOut-footer {
    text-align: center;
}

.modal-timesOut-body--image {
    display: block;
    width: 100%;
}

.modal-timesOut-body--title {
    text-align: center;
    width: 100%;
    margin: 0;
    margin-top: .8em;
}

.modal-timesOut-body p {
    text-align: center;
    width: 100%;
}