/* TABS MESSAGE */
.wrapper-message-tabs {
  width: 100%;
  max-width: 1000px;
  margin: 0;
  padding: 0 1.5em 0.5em 1.5em;
}

.message-tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.message-tabs input[type=radio]:checked ~ label{
  color: #DADADA;
  font-weight: bold;
  transition: color ease 0.2s;
}

.message-tabs label {
  color: #DADADA;
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: bold;
  width: 50%;
  text-align: center;
  border-bottom: 3px solid #DADADA;
}

.message-tabs label .fas {
  font-size: 20px;
  padding: 0 0.5em;
}

.number-no-read {
  font-weight: normal;
  font-style: italic;
}

.message-tabs .message-tabs--content {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem 1em 3em 1em;  
  -webkit-animation: scale 0.7s ease-in-out;
    -moz-animation: scale 0.7s ease-in-out;
    animation: scale 0.7s ease-in-out;    
}

@keyframes scale {
  0% {
    transform: scale(0.9);
    opacity: 0;
    }
  50% {
    transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.message-tabs input[type="radio"] {
  display: none;
} 
.message-tabs input[type="radio"]:checked + label {
    color: #e88b18;
    border-bottom: 3px solid #e88b18;
    background-color: #ffeece;
  /* color: #5A93B6;
  border-bottom: 3px solid #5A93B6; */
}
.message-tabs input[type="radio"]:checked + label + .message-tabs--content {
  display: block;
} 

@media screen and (max-width: 750px) { 
  .wrapper-message-tabs {
    padding: 0;
  }
}

@media screen and (max-width: 450px) { 
  .message-tabs .message-tabs--content {
    padding: 1rem 0.25em 3em 0.25em; 
  }

  .message-tabs label { font-size: 12px;}
}