/****************************/

.modal-message--compose-body {
  padding: 3em 1em 4em 1em;

  display: -webkit-box; 
      display: -webkit-flex;  
      display: -ms-flexbox; 
      display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.button-modal-message-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-message-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-message-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-primary:disabled, .button-modal-message-primary:disabled:hover {
  background-color: #DDD;
  border: 1px solid #DDD;
  cursor: not-allowed;
}


/* CONTENT RESPONSE */

.container-modal-message__content-response {
  background-color: #ECECEC;
  padding: 2em 1em 4em 1em;
}

.content-response-instruction {
  font-size: 12px;
  color: #9098AC;
}

.content-response-instruction h2 {
  font-size: 12px;
  color: #9098AC;
}

.title-dropzone {
  font-size: 14px;
  font-weight: bold;
  color: #222;
  margin-top: 4em;
  padding-bottom: 0.5em;
  border-top: 1px solid #DDD;
  padding-top: 0.5em;
}

/* .form-group-message {
  margin: 3em auto 1em auto;
  border-top: 1px solid #DADADA;
  padding-top: 1em;
} */

.modal-message--compose-body .button-modal-message-primary {
  width: 150px
}

.form-group-message-compose {
  width: 100%;
}

.form-group-message-compose label {
  font-size: 14px;
}

.form-group-message-compose textarea {
  width: 96%;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  color: #222;
  font-size: 14px;
  height: 100px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}



.modal-message--compose-body input {    
  width: 100%; 
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  font-size: 14px;

  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}

.modal-message--compose-body input::-webkit-input-placeholder,
.modal-message--compose-body textarea::-webkit-input-placeholder {  
  color: #222; 
}

.modal-message--compose-body input:-moz-placeholder,
.modal-message--compose-body textarea:-moz-placeholder { 
  /* Firefox 18- */ color: #222;  
}

.modal-message--compose-body input::-moz-placeholder,
.modal-message--compose-body textarea::-moz-placeholder {  
  /* Firefox 19+ */   color: #222; 
}

.modal-message--compose-body input:-ms-input-placeholder,
.modal-message--compose-body textarea:-ms-input-placeholder {     
  color: #222;  
}

.modal-message--compose-body .selectTrello {
  width: 100%;
}

.content-dropzone {
  border: 0
}

.modal-message--compose-body .selectTrello .css-1wa3eu0-placeholder {
  color: #222;
  font-size: 14px;
}

.modal-message--compose-body .selectTrello .css-26l3qy-menu {
  font-size: 14px;
}



/* MQ 500px */

@media screen and (max-width: 500px) {
  .container-modal-message .modal-footer {
      text-align: left;
  }
}
