.wrapper-content {
  margin-left: 150px;
  margin-top: 75px;
}

/****************************/

.center-container {
  padding: 1em;
  padding-bottom: 75px;

  display: -webkit-box; 
      display: -webkit-flex;  
      display: -ms-flexbox; 
      display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.container_two-col {
width: 48%;
}



/* MQ 920px */

@media screen and (max-width: 920px) {
  .container_two-col {
    width: 100%;
    margin-bottom: 1em;
}
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-content {
      margin-left: 0;
  }

  .container_two-col {
    width: 100%;
  }

  .center-container {
    padding-bottom: 125px;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .center-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}


/* FONT SIZE CHANGER */

/* .content-font-size-changer {
  position: fixed;
  z-index: 3;
  right: 0.5em;
  background-color: #DDD;
  padding: 0.5em;
} */

.content-font-size-changer {
  padding: 0.5em;
  margin-top: -18px;
  text-align: right;
  height: 25px;
  display: none;
}

.font-size-changer {
  margin-top: -15px;
  /* position: absolute; */  
  position: relative;  
  height: 35px !important;
  width: 100% !important;
}

.wrapper-iframe .font-size-changer {
  width: 100%;
}

.font-size-changer-buttons {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.center-container .font-size-changer .font-size-changer-buttons > div {
  border: 1px solid #DDD;
  line-height: 30px;
  font-weight: bold;
  color: #27466C;
  background-color: #EFEFEF;
}

.center-container .font-size-changer .font-size-changer-buttons > div:hover {
  color: #A2A2A2;
}

.center-container .font-size-changer .font-size-changer-buttons .font-size-up span {
  font-size: 30px !important;
}

.center-container .font-size-changer .font-size-changer-buttons .font-size-up,
.center-container .font-size-changer .font-size-changer-buttons .font-size-down {
  height: 33px !important;
}

.content .vviYD {
  margin-top: 0 !important;
}


/* CHAT STYLE */
#chat-widget-container{
  bottom: 50px !important;
}

@media screen and (max-width: 750px) {
  .lc-dw9r6j {
      height: 80% !important;
      margin-top: 20%;
  }

  #chat-widget-container {
      margin-bottom: 55px !important
  }
}

/* FIX CHAT HEIGHT */
@media screen and (max-width: 450px) {
    #chat-widget-container {
        height: 530px !important;
    }
}

@media screen and (max-width: 450px) {
#target-one img {
  max-width: 300px;
}

.content-form img {
  max-width: 200px;
}
}