.comments-modal-title {
    margin: 0;
    margin-top: -15px;
}

.modal-comments-body {
    padding: 35px 0
}

.modal-comments-body .txt-no-comments {
    text-align: center;
    color: #CCC;
}

.modal-comments-body .txt-no-comments i {
    display: block;
    margin: 0 auto 0.5em auto;
    font-size: 30px;
}

