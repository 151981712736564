.title-table-progress {
    color: #2A426D;
    font-size: 16px;
}

.content-table-homework td.table-progress--title {
    color: #000;
    font-weight: bold;
    font-size: 16px !important;
}

.content-table-homework.content-table-progress th {
    font-size: 12px;
}

.content-table-homework.content-table-progress td {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.letter-progress {
    background-color: #E4E4E4;
    padding: 2px 5px;
    font-weight: bold;
    margin-left: 5px;
}

.footer-table-progress .letter-progress {
    background-color: #FFF;
}

.footer-table-progress {
    background-color: #E7F1EF;
}

.content-table-homework.content-table-progress tr {
    padding-right: 5px;
}

.wrapper-download .button-primary {
    font-weight: 600;
}

@media screen and (max-width: 750px) {
    .content-table-progress tr {
        padding: 1em;
    }
    .content-table-progress td { 
        border: none;
        /* border-bottom: 1px solid #eee;  */
        position: relative;
        padding-left: 0px;
        padding-top: 10px !important;
        padding-bottom: 0px !important; 
    }

    .content-table-progress td:before { 
        position: relative;
        top: 0px;
        left: 0px;
        /* width: 120px;  */
        padding-right: 10px; 
        white-space: nowrap;
        font-size: 11px;
        font-weight: bold;
    }

    .content-table-homework.content-table-progress tr.footer-table-progress {
        background-color: #E7F1EF;
    }

    .content-table-progress td:nth-of-type(1):before { display: none; }
    .content-table-progress td:nth-of-type(2):before { content: "1er Período:"; }
    .content-table-progress td:nth-of-type(3):before { content: "2do Período:"; }
    .content-table-progress td:nth-of-type(4):before { content: "3er Período"; }
    .content-table-progress td:nth-of-type(5):before { content: "4to Período"; }
    .content-table-progress td:nth-of-type(6):before { content: "5to Período"; }
    .content-table-progress td:nth-of-type(7):before { content: "6to Período"; }
    .content-table-progress td:nth-of-type(8):before { content: "Cumulativo"; }
}