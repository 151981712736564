.user-comp_menu {
  clear: both;
  margin: 0 0 0 -80px;
  width: 300px;
  background: url(../../../assets/images/UserComp-bg.jpg) no-repeat left top;
  background-color: #FFF;
  cursor: default;
  padding: 0.5em;
  text-align: left;
  z-index: 100;
  position: fixed;
  display: inline-block;

  -webkit-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
-moz-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
  box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
}

.user-comp_menu ul {
  padding: 0;
}

ul.user-comp_submenu {
  color: #231F20;
  font-size: 20px;
  padding: 1em;
  font-weight: bold;
}

.user-comp_submenu li {
  margin-bottom: 1em;
}

.user-comp_submenu li, .user-comp_submenu li img {
  vertical-align: middle;
}

.user-comp_submenu li a {
  color: #231F20;
  text-decoration: none;
}

.user-comp_submenu li .fas {
  margin-right: 10px;
  color: #27466C;
}

.user-comp_submenu li a:hover, .user-comp_submenu li a:hover .fas {
  color: #838383;
  cursor: pointer;
}





.user-comp_info-user {
  width: 95%;
  text-align: center;
}

/* Tipography dropdown */

.user-comp_info-user h1 {
  font-size: 20px;
  line-height: 10px;
  margin-bottom: 0.5em;
}

.user-comp_info-user h2 {
  font-size: 16px;
  line-height: 10px;
  margin-bottom: 0.5em;
  font-weight: normal;
}

.user-comp_info-user h4 {
  font-size: 12px;
  font-weight: normal;
}


/* Avatar Inside */
.user-comp_info-user .double-border {
  border-radius: 50%;
  background-color: transparent;
  border: solid 4px transparent;
  padding: 3px;
  width: 100px;
  height: 100px;
  margin: 0.5em auto;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #B5DFE9 50%,#E88B19 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.user-comp_info-user .double-border img {
  border-radius: 50%;
  width: 100px;
  box-shadow: 0 0 0 3px #FFF;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
    .user-comp_profile-wrapper {
      width: 70px;
  }

  .user-comp_profile .user-name {
      display: none;
  }

  /* Avatar Principal */
  .double-border--principal {
      width: 30px;
      height: 30px;
  }

  .double-border--principal img {
      width: 30px;
  }

  /* dropdown */
  .user-comp_menu {
      margin: 0 0 0 -220px;
  }

  /* Avatar inside */
  .user-comp_info-user .double-border {
    width: 70px;
    height: 70px;
  }
  
  .user-comp_info-user .double-border img {
    width: 70px;
  }

  /* DropDown Container */
  .user-comp_menu {
    margin: 0 0 0 -136px;
    width: 200px;
  }

  ul.user-comp_submenu {
   font-size: 15px;
    padding: 1em;
  }
}
