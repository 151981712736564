/******/

.preview-docs-message {
  width: 100%;
  margin-top: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
        align-items: stretch;

        -ms-flex-line-pack: justify;
        align-content: space-between
}

.preview-docs-message--seven-col {
  width: 12%;
  padding: 0.25em;
}

.preview-docs-message--seven-col img {
  width: 100%;
  height: 105px;
  object-fit: cover;
  border: 1px solid #DDD;
}

.preview-docs-message--seven-col p {
  font-size: 12px;
  white-space: nowrap; 
  width: 95%; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0.25em 0;
  color: #525d79;
}

.preview-docs-message--seven-col p:hover {
  overflow: visible;
  white-space: nowrap;
}





/* MQ 750px */

@media screen and (max-width: 750px) {
  .preview-docs-message--seven-col {
    width: 20%;
    margin-bottom: 1em;
  }  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .preview-docs-message--seven-col {
    width: 30%;
  }  
}

