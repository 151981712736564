.item-matter--five-col {
  width: 18%;
  padding: 0.25em
}

.item-matter--five-col a {
  text-decoration: none;
  cursor: pointer;
}

.item-matter {
  background-color: #FFF;
  text-align: center;
  border-radius: 15px;
  padding: 0.25em;  
}

.item-matter:hover {
  background-color: #D9E8F2
}

.item-matter .item-active {
  background-color: #D9E8F2;
  border-radius: 15px;
  padding: 0.25em;
}

.item-matter img {
  width: 100%;
}

.item-matter-title-lesson {
    font-size: 12px;
    color: #222;
    font-weight: bold;
    margin: 0.25em 0 0 0
}




/* MQ 450px */

@media screen and (max-width: 450px) {
  .item-matter--five-col {
    width: 30%;
  }
}