.background-teams {
  margin: 0;
  padding: 0;
  background-color: #f3f2f1;
}

.microsoftLogo {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  border-bottom: 1px solid #cccccc6b;
}

.headerLogo {
  margin-right: 1rem;
  max-width: 32px;
  width: 32px;
}

.headerIcon {
  cursor: pointer;
  margin-left: auto;
  text-align: right;
}

.headerIcon span {
  display: inherit;
}

.headerIcon i {
  color: #3e3e3e;
  position: relative;
  top: 7px;
}

.container-teams {
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 100%;
}

.teamsButton,
.teamsButtonInverted {
  border-radius: 3px;
  height: 2.25rem;
  font-size: .875rem;
  font-weight: 600;
  padding: 0 1.25rem;
  text-decoration: none;
}

.teamsButton svg,
.teamsButtonInverted svg {
  margin-right: 0.5rem;
  font-size: .8rem;
}

.teamsButton {
  background-color: #6264a7;
  border: 1px solid hsla(0, 0%, 100%, .04);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .15);
}

.teamsButton:hover {
  background: #464775;
  border-color: transparent;
}

.teamsButton:active {
  background: #33344A;
  border-color: transparent;
}

.teamsButtonInverted {
  border: 1px solid #e3e5e9;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .075);
}

.teamsButtonInverted:hover {
  background-color: #f3f2f1;
}

.teamsButtonFullWidth {
  width: 100%;
}

.splashImage {
  max-width: 650px;
  margin-top: 3rem;
}

.uTextCenter {
  text-align: center;
}

div[class*="ms-TextField-wrapper"]:after {
  border-bottom: 2px solid #6264a7;
}

.signinHeader {
  margin-bottom: 2.1rem;
}

.signinSubHeader {
  margin-top: 0 !important; /* needs to override Fabric */
  margin-bottom: 0.5rem;
}

/*
*  New Meeting Styles
*/

.newMeetingContainer {
  display: flex;
  height: calc(100vh - 10vh);
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
}

.newMeetingInput input {
  border-radius: 3px;
}

.newMeetingInputIcon i {
  margin-right: 1.1rem;
}

div[class*="ms-ComboBox"]::after {
  border: none; /* override fabric border */
}

.newMeetingDatePicker {
  margin-right: 0.5rem;
}

.newMeetingDatePickerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.newMeetingDatePickerContainer > i {
  margin-right: 1rem;
}

.newMeetingDatePicker i {
  display: none; /* hides fabric date icon to match design */
}

.newMeetingComboBox {
  flex: 0 0 30%;
}

.newMeetingPicker > div {
  margin-bottom: 0.8rem;
}

.newMeetingPickerIcon {
  transform: rotate(180deg);
  position: relative;
  font-size: 1.1rem;
  left: 15px;
  top: -8px;
}

.newMeetingPickerIncrement {
  position: relative;
  left: 15px;
  top: 7px;
}

@media (min-width: 600px) {
  .newMeetingPicker {
      display: flex;
  }

  .newMeetingComboBox {
      flex: 1 1 auto;
      max-width: 100px;
  }

  .newMeetingPickerIcon {
      padding: 0 0.5rem;
      left: auto;
  }
}

.newMeetingButtons {
  display: none;
}

.newMeetingButtonsMobile {
  display: block;
  padding: 0 1.5rem;
}

@media (min-width: 500px) {
  .newMeetingButtons {
      display: block;
  }

  .newMeetingButtonsMobile {
      display: none;
  }
}

/*
*  Meeting Card Styles
*/

.meetingSuccess {
  align-self: center;
  display: flex;
  margin-bottom: 1.5rem;
  width: 50px;
}

.teamsLink {
  color: #6264a7;
}

.meetingCardContainer {
  display: flex;
  box-shadow: 0px 9px 10px 1px #00000014;
  flex-direction: column;
  margin-top: 4.3rem;
  padding: 2rem;
}

@media (min-width: 600px) {
  .meetingCardContainer {
      width: 400px;
  }
}

.meetingCardBody {
  border: 1px solid #cccccc6b;
  border-radius: 3px;
  padding: 0 1rem 1.3rem 1rem;
}

.meetingCardBody > div > span {
  display: none; /* sigh...have to override injected html */
}

.meetingCardBody span {
  font-size: 0.9rem !important; /* override for injected html */
}

.meetingCardHeader,
.meetingCardSubHeader {
  margin-bottom: 1.4rem;
  text-align: center;
}

.meetingCardSubHeader {
  line-height: 1.5;
  max-width: 400px;
}

.meetingCardDialInfo,
.meetingCardUrl {
  margin-bottom: 1rem;
  display: block;
  font-size: 1rem;
}

.meetingCardConfID {
  margin-bottom: 1rem;
}

.copyButton {
  align-self: flex-end;
  margin-top: 1.6rem;
}

/*
* Spinner styling
*/
.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

div[class*="ms-Spinner-circle"] {
  border-color: #6264a7 #a7a8c7 #a7a8c7;
}

.ms-ComboBox-optionsContainerWrapper {
  max-height: 300px;
}

.wrapper-content-meet .DateInput, .DateInput input {
  width: 100%;
}

.wrapper-content-meet .SingleDatePicker, .wrapper-content-meet .SingleDatePickerInput {
  display: block;
}

.wrapper-content-meet .DateInput_input__focused, .wrapper-content-meet .DateInput_input {
  height: 37px;
  font-size: 12px;
}

/* New Styles */
.calendarTeams-Content {
  padding-bottom: 100px;
}

.calendarTeams-Content .rbc-toolbar .rbc-toolbar-label {
  text-align: left;
  text-transform: capitalize;
}

.content-meet--helptext {
  font-size: 11px;
  color: #808080;
  margin-top: -10px;
}

.content-meet--col {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.content-meet--col .form-wizard-fields {
  /* flex: 1 1 auto; */
  width: 150px;
}

.date-contentIcon {
  color: #CCC;
  padding: 10px;
}

.label-dateMeet {
  display: block;
  font-size: 12px;
  margin-bottom: 0.5em;
}

.sizeInput-200 .css-bg1rzq-control {
  width: 200px;
}

.fix-textarea textarea {
  margin-top: 0 !important;
  margin-bottom: 10px;
}


.calendarTeams-Content .rbc-day-slot .rbc-event {
  left: 0 !important;
  width: 100% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event:nth-child(2) {
  left: 10% !important;
  width: 90% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event:nth-child(3) {
  left: 15% !important;
  width: 90% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event-label {
  vertical-align: middle;
  font-weight: bold;
  font-size: 70%;
}

.calendarTeams-Content .rbc-day-slot .rbc-event-content {
  line-height: inherit;
  font-size: 70%;
}

.calendarTeams-Content .rbc-day-slot .rbc-event {
  border-radius: 2px;
  /* background-color: #DDDFF9 !important;
  color: #3D3E74;
  border: 1px solid #babde0; */

  background-color: #b5dddb !important;
  color: #2f5550;
  border: 1px solid #6fa4a2;
}

/*dialog style*/
.dialog-teams {
  position: fixed;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 349px;

}

.modalContent-MeetNow {
  padding: 10px;
}

.modalContent-MeetNow button {
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
}

.buttonTeams-Primary {
  background: #6164A6;
  color: #FFF;
  border: 1px solid #6164A6;
  border-radius: 4px;
  height: 30px;
}

.buttonTeams-Primary:hover {
  background: #4d5085;
}

.buttonTeams-Default {
  background: #FFF;
  color: #424242;
  border: 1px solid #E2E5E9;
  border-radius: 4px;
  height: 30px;
}

.buttonTeams-Default:hover {
  background: #efefef;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-overlay.with-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}


.modalContent-MeetDetail {
  padding: 10px;
}

.modalContent-MeetDetail button {
  width: 100px;
  cursor: pointer;
}

/* Content Ppal */
.meetDetail-content {
  margin-bottom: 10px;
}

.meetDetail-content--title {
  font-size: 18px;
  font-weight: bold;
}

.meetDetail-content--date {
  font-size: 14px;
}


/* Aditional Information */
.meetDetail-aditionalInfo {
  margin-top: 10px;
  border-top: 1px solid #DDDCDD;
  padding-top: 10px;
}

.aditionalInfo-Organizer {
  list-style: none;
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 0;
}

.aditionalInfo-Organizer li {
  display: inline-block;
}

.aditionalInfo-Organizer li:last-child {
  color: #999;
}

.aditionalInfo-Organizer li:not(:last-child)::after {
  content: "-";
  display: inline-block;
  margin-left: .25rem;
  margin-right: .25rem;
  color: #999;
}


/* Aditional Information GUEST */
.aditionalInfo-Guest {
  list-style: none;
  font-size: 12px;
  padding-left: 0;
}

.aditionalInfo-Guest li {
  display: inline-block;
}

.aditionalInfo-Guest li:not(:last-child)::after {
  content: ",";
  display: inline-block;
  margin-right: .25rem;
}

li.aditionalInfo-Guest--title {
  display: block;
  color: #999;
}

li.aditionalInfo-Guest--title:not(:last-child)::after {
  content: none;
}


/* Modal successful */
.modalContent-Successful {
  text-align: center;
  padding: 2em;
}

.modalContent-Successful--button {
  text-align: right;
  margin-top: 2em;
}

.modalContent-Successful--button button {
  cursor: pointer;
  width: 80px;
}

.modalContent-Successful--desc {
  font-weight: bold;
  font-size: 14px;
  margin-top: 2em;
}

.no-scroll {
  overflow: hidden;
  padding-right: 15px;
}

.modal-is-loading {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}


/*.dialog style*/

