.color-404 {
	color: #DC4771 !important;
}

.color-500 {
	color: #3BA5E4 !important;
}

.color-white {
	color: #FFF !important;
}

.full-col {
	width: 80%;
	margin:  auto;
}

.full-col__mod-txt {
	text-align: center;
	padding: 3em;
}

.full-col img {
	width: auto !important;
}

.bg-mantenimiento {
	background-color: #27466C;
	height: 100vh;
}

img.full-col__mod-txt-logo {
	width: 350px !important;
	max-width: 100% !important;
}

.full-col__mod-txt h1 {
	font-size: 30px;
	line-height: 1.2em;
	margin-top: 1.5em;
}

/****/

@media(min-width: 1100px) {
    #page > img {
		width: 65%;
	}
}

@media(max-width: 830px) {
	.DosCol .mod-txt {
		text-align: center;
	}
}

@media(max-width: 430px) {
    .mod-txt h1 {
		font-size: 25px;
		line-height: 1.2em;
	}
}