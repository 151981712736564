.container-modal-message {
  width: 90%;
  max-width: 850px;
  background-color: #FFF;
  margin: 2em auto;
  padding: 1em;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
  border-radius: 15px;
}

/****************************/

.modal-message-body {
  padding: 3em 1em 4em 1em;

  display: -webkit-box; 
      display: -webkit-flex;  
      display: -ms-flexbox; 
      display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.modal-message-body--col-name {
width: 60%;
  font-weight: bold;
}

.modal-message-body--col-date {
  width: 40%;
  text-align: right;
  color: #9098AC;
  font-size: 14px;
}

.modal-message-body--col-message {
  width: 100%;
  border-top: 1px solid #DDD;
  padding-top: 1em;
  margin-top: 1em;
}

h1.title-modal-message {
  margin: 0;
  font-size: 22px;
  padding: 0 0.6em;
}


.button-modal-message-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-message-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-message-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


/* CONTENT RESPONSE */

.container-modal-message__content-response {
  background-color: #ECECEC;
  padding: 23em 1em 4em 1em;
}

.content-response-instruction {
  font-size: 12px;
  color: #9098AC;
}

.content-response-instruction h2 {
  font-size: 12px;
  color: #9098AC;
}

/* .form-group-message {
  margin: 3em auto 1em auto;
  border-top: 1px solid #DADADA;
  padding-top: 1em;
} */

.container-modal-message__content-response .button-modal-message-primary {
  width: 150px
}

.form-group-message label {
  font-size: 14px;
}

.form-group-message textarea {
  width: 96%;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  color: #222;
  font-size: 14px;
  height: 100px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}

.form-group-message textarea::-webkit-input-placeholder {  
  color: #222; 
}

.form-group-message textarea:-moz-placeholder { 
  /* Firefox 18- */ color: #222;  
}

.form-group-message textarea::-moz-placeholder {  
  /* Firefox 19+ */   color: #222; 
}

.form-group-message textarea:-ms-input-placeholder {     
  color: #222;  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .container-modal-message .modal-footer {
      text-align: left;
  }
}

@media screen and (max-width: 450px) {
  .modal-message-body--col-name {
    width: 100%;
  }

  .modal-message-body--col-date {
    width: 100%;
    text-align: left;
    margin-top: 0.5em;
  }

 .MuiDialog-paper { margin: 0 !important;}
 .MuiDialog-paperFullWidth {
    width: calc(100% - 25px) !important;
  }
  .MuiDialogContent-root {
    padding: 8px 8px !important;
  }

  .modal-message-body { padding: 2em 0.5em 4em 0.5em; font-size: 14px;;}
  h1.title-modal-message {padding: 0 0.25em; font-size: 15px;  }
  .modal-footer {padding: 0.5em;}
  .modal-message-body--col-date { font-size: 12px; }

  
}


