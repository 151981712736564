.wrapper-home-task {
  padding: 1em;
  background-color: #E8E8E8;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
-moz-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
  box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
}

.wrapper-home-task h2 {
  color: #E88B18;
  font-size: 20px;
  margin: 0 0 0.5em 0;
}


/******/

.content-item-homework {
  border-bottom: 1px solid #DDD;
  padding: 1em 0.5em;
  color: #222;
  background-color: #FFF;
  font-size: 15px; 


  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;

  -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.wrapper-home-task .content-item-homework {
  background-color: #E8E8E8;
  padding: 0.5em 0.5em;
}

.item-homework--button {
  text-align: right;
}

.item-homework--homework {
  width: 70%;
}

.homework-description, .homework-icon-matter {
  display: inline-block;
  vertical-align: middle;
}

.homework-icon-matter {
  font-size: 40px;
  margin-right: 0.25em;
}

.txt-homework {
  color: #222;
  font-size: 14px;
  margin: 0 0 0.25em 0;
}

.date-homework {
  color: #9098AC;
  font-size: 13px;
  margin: 0.25em 0 0 0;
}

.matter-homework {  
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9098AC;
}

/* button */

.button-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 1em 1.5em;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    margin: 0.5em auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}






/* MQ 750px */

@media screen and (max-width: 750px) {
      
}

