
.wrapper-quiz-list {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #2C3140;
}

.wrapper-quiz-list h4.title-lessons {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
}

.wrapper-quiz-list h3 {
font-weight: 800;
font-size: 16px;
margin: 0 0 2em 0;
}


/**********/

.container-quiz-lesson a {
border-bottom: 1px solid #DDD;
padding: 1em ;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 15px; 
cursor: default;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;

  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container-quiz-lesson a:hover {
background-color: #D9E8F2;
-webkit-box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
-moz-box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
}

.container-quiz-lesson:first-child a {
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}

.container-quiz-lesson:last-child a {
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
border: 0;
}


.col-left--quiz-lesson {
width: 60%;
}

.col-right--quiz-lesson {
width: 160px;
text-align: center;
}


.container-quiz-lesson .icon-finished-lesson {
font-size: 25px;
color: #B0D8B9;
}



/* MQ 500px */

@media screen and (max-width: 500px) {
.container-quiz-lesson a {
  -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-left--quiz-lesson {
  width: 100%;
  margin-bottom: 1em;
}
}

