.wrapper-table-homework-task {
  width: 100%;
  /* max-width: 850px; */
  padding: 0;
}

.wrapper-table-homework-task h1 {
  font-weight: 800;
  font-size: 20px;
  margin: 0 0 1em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

/******/

table.content-table-homework {
  border-collapse: collapse;
  width: 100%;
  margin: 2em 0;
}

.content-table-homework tr/*:nth-of-type(odd)*/ {
  text-align: left;
  vertical-align: top;
  color: #2A426D;
  font-size: 13px;
}

.content-table-homework tr {
  border-bottom: 1px solid #EBEBEB;
}

.content-table-homework thead tr {
  border-bottom: 0;
}

.content-table-homework th {
  padding-bottom: 1em;
}

.content-table-homework td {
  padding: 1em 0;
  font-size: 16px;
}

.content-table-homework.content-table-progress td,
.content-table-homework.content-table-progress th {
  padding: 10px;
}

.content-table-homework.content-table-progress th {
  border-bottom: 1px solid #EFEFEF;
}

.content-table-homework .table-homework--txt-matter {
  font-size: 11px;
  color: #9098AC;
  margin: 0.25em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.content-table-homework .table-homework--title {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.content-table-homework .table-homework--date {
  color: #222;
  font-size: 12px;
}

.content-table-homework .table-homework--name-matter {
  font-size: 14px;
  color: #000;
}

/* button */

.button-table-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 0.8em;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-table-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}



/* MQ 750 */


@media screen and (max-width: 750px) {
  table.content-table-homework, .content-table-homework thead, 
  .content-table-homework tbody, 
  .content-table-homework th, 
  .content-table-homework td, 
  .content-table-homework tr { 
    display: block; 
  }

  .content-table-homework thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .content-table-homework tr { border-bottom: 8px solid #FFF; margin-bottom: 1em; }

  .content-table-homework td { 
    border: none;
    border-bottom: 1px solid #EBEBEB; 
    position: relative;
    padding-left: 50%;
    font-size: 13px; 
  }

  .content-table-homework td:before { 
    position: absolute;
    top: 15px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
    content: attr(data-column);
    color: #2A426D;
    font-weight: bold;
  }

  .content-table-homework tr {
    background-color: #EFEFEF;
  }

  .button-table-homework {
    width: auto;
    padding: 0.8em 2em;
    margin: 0;
  }
}


/* MQ 500 */

@media screen and (max-width: 500px) { 
  .content-table-homework td {  font-size: 12px; }
}

@media screen and (max-width: 750px) { 
  .wrapper-table-homework {
      margin: auto;
  }
}

/* MQ 450 */

@media screen and (max-width: 450px) { 
  .content-table-homework td {  font-size: 10px; }
  .content-table-homework .table-homework--title { font-size: 14px; }
}

