.container-modal-tries {
    width: 90%;
    max-width: 550px;
    background-color: #FFF;
    margin: 2em auto;
    padding: 1em;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
    border-radius: 15px;
  }
  
  /****************************/
  
  .modal-tries-body {
    padding: 1em;
    line-height: 1.5em;
    font-size: 16px;
    color: #222
  }
  
  
  h3.title-modal-tries {
    display: block;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
  }
  
  .modal-footer-tries {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
  