.container-modal {
  width: 80%;
  max-width: 550px;
  background-color: #FFF;
  margin: 2em auto;
  padding: 1em;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
  border-radius: 15px;
}

/****************************/

.container-modal-body {
  padding: 1em;

  display: -webkit-box; 
      display: -webkit-flex;  
      display: -ms-flexbox; 
      display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.modal-body_two-col {
width: 48%;
  text-align: center;
}

h1.title-modal {
  margin: 0;
  font-size: 22px;
  padding: 0 1em;
}

h1.result-modal {
  font-size: 50px;
}

h1.result-modal span {
  font-size: 14px;
  font-weight: normal;
  display: block;
}

.modal-footer {
  padding: 1em;
}

.button-modal-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

/* Progress circle */


.c100 .bar {
position: absolute;
border: 0.08em solid #7EC766;
width: 0.84em;
height: 0.84em;
clip: rect(0em, 0.5em, 1em, 0em);
border-radius: 50%;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-ms-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
}

.c100 {
position: relative;
font-size: 120px;
width: 1em;
height: 1em;
border-radius: 50%;
/*float: left;*/
margin: auto; /*0 0.1em 0.1em 0;*/
background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
-webkit-box-sizing: content-box;
-moz-box-sizing: content-box;
box-sizing: content-box;
}

.c100.big {
font-size: 200px;
}

.c100 > span {
position: absolute;
width: 100%;
z-index: 1;
left: 0;
top: 0;
width: 5em;
line-height: 5em;
font-size: 0.2em;
color: #cccccc;
display: block;
text-align: center;
white-space: nowrap;
-webkit-transition-property: all;
-moz-transition-property: all;
-o-transition-property: all;
transition-property: all;
-webkit-transition-duration: 0.2s;
-moz-transition-duration: 0.2s;
-o-transition-duration: 0.2s;
transition-duration: 0.2s;
-webkit-transition-timing-function: ease-out;
-moz-transition-timing-function: ease-out;
-o-transition-timing-function: ease-out;
transition-timing-function: ease-out;
}

.c100:after {
position: absolute;
top: 0.08em;
left: 0.08em;
display: block;
content: " ";
border-radius: 50%;
background-color: #FFF;
width: 0.84em;
height: 0.84em;
-webkit-transition-property: all;
-moz-transition-property: all;
-o-transition-property: all;
transition-property: all;
-webkit-transition-duration: 0.2s;
-moz-transition-duration: 0.2s;
-o-transition-duration: 0.2s;
transition-duration: 0.2s;
-webkit-transition-timing-function: ease-in;
-moz-transition-timing-function: ease-in;
-o-transition-timing-function: ease-in;
transition-timing-function: ease-in;
}

.c100 .slice {
position: absolute;
width: 1em;
height: 1em;
clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p50 .bar {
-webkit-transform: rotate(180deg);
-moz-transform: rotate(180deg);
-ms-transform: rotate(180deg);
-o-transform: rotate(180deg);
transform: rotate(180deg);
}

.c100:hover {
cursor: default;
}

.c100:hover > span {
color: #7EC766;
}

.c100:hover:after {
top: 0.04em;
left: 0.04em;
width: 0.92em;
height: 0.92em;
}


/* Close Windows */

button.close-windows { 
  padding: 0 0.5em 0.5em 0.5em; 
  border: 0;
  color: #898D93;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

button.close-windows:hover { 
  color: #b3bcbf;
}

.modal-text-right {text-align: right;}



/* MQ 500px */

@media screen and (max-width: 500px) {
  .modal-body_two-col {
      width: 100%;
  }

  .modal-footer {
      text-align: center;
  }
}
