.wrapper-menu {
  width: 135px;
  background-color: #4E66C4;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30% /*before 34%*/;
  height: auto;
  vertical-align: top;
}

span.lesson-mark {display: none;}
.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  /* display: block; */
  background-color: transparent;
}

.navigation-menu li a:hover img { 
  background-color: #567B91;
  border-radius: 6px;
  }
  
  .navigation-menu li.active img, .navigation-menu li.active:hover img {
    background-color: #27466C;
    border-radius: 6px;
  }
  
  .navigation-menu li.active a {
    color: #27466C;
    font-weight: bold;
    font-size: 12px;
  }

li.highlight-item a {
  color: #FFF700;
}

.nav-kinder img {
  width: 70px !important;
}

.scroll-bar.wrapper-menu {
  overflow: auto;
}

/* Style SCROLL BAR */
.scroll-bar.wrapper-menu .navigation-menu {
  height: 665px;
}

.navigation-menu, .wrapper-menu {
  scrollbar-width: thin;
  scrollbar-color: #6ba7cc #4f88ab;
}

.wrapper-menu::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #4f88ab
}

.wrapper-menu::-webkit-scrollbar {
	width: 6px;
	background-color: #4f88ab;
}

.wrapper-menu::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #6ba7cc;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
      display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-between;
    -webkit-box-orient: horizontal;

            -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;

            -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
    background-color: #27466C;
  }

  .navigation-menu li.active a {
    color: #FFF;
  }

  .navigation-menu .navigation-menu--item {
    display: none;
  }
  .scroll-bar.wrapper-menu { overflow: hidden; } 
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-menu {
    height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }

  .navigation-menu li.active a {
    font-size: 11px;
  }

  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .wrapper-menu {
    bottom: 15px /* 21 change for delete txt*/;
    }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
}

