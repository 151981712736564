.wrapper-not-authorized {
    background: url(../../../assets/images/bg.jpg) no-repeat center center fixed;
	height: 100vh;
	background-size: cover;
}
  
.not-authorized {
    color: #9A9A9A;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 1.4em;
	margin-top: 0px;
	font-weight: 300; 
}

#page {
	text-align: center;
}

#page img {
	width: 100%;
}

#page h1 img {
	width: auto;
}

#content {	
	width: 90%;
	margin: auto;
	display: -webkit-box;	
	display: -webkit-flex;	
	display: -ms-flexbox;	
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.DosCol {
	width: 50%;
	margin-top: 50px;
}

/* 

	4.1. MEDIA QUERIES ESTRUCTURA GENERAL

*/


@media screen and (max-width: 830px){
	.DosCol {
		width: 100%;
		margin-top: 10px;
	}
}

/* 

	5. MÓDULOS DE CONTENIDO

*/

.mod-txt {
	padding: 1em;
	text-align: center;
}

.DosCol .mod-txt {
	text-align: left;
	padding: 3em;
}

.DosCol .mod-txt h1 {
	font-size: calc(60px + 0.3vw);
	font-weight: 900;
	line-height: 1.3em;
	color: #F47D45;
}

.DosCol .mod-txt h2 {
	font-size: calc(14px + 0.3vw);
    font-weight: 900;
    margin-bottom: 1.2em;
    text-transform: uppercase;
    color: #27466C;
}

.DosCol .mod-txt p {
	margin-top: 0.25em;
	font-size: 17px;
	font-weight: 400;
	line-height: 1.1em;
	color: #27466C;
}

@media(min-width: 1100px) {
    #page > img {
		width: 65%;
	}
}

@media(max-width: 830px) {
	.DosCol .mod-txt {
		text-align: center;
	}
}

@media(max-width: 430px) {
    .mod-txt h1 {
		font-size: 25px;
		line-height: 1.2em;
	}
}