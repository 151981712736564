.wrapper-menu {
  width: 135px;
  background-color: #4E66C4;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30%;
  height: auto;
  vertical-align: top;
}

.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  display: block;
  background-color: transparent;
}

.navigation-menu li a:hover img, .navigation-menu li.active img {
  background-color: #40539D;
  border-radius: 15px;
}

li.highlight-item a {
  color: #FFF700;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* 28 change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
      display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-between;
    -webkit-box-orient: horizontal;

            -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;

            -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
      background-color: #40539D;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-menu {
    height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }


  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .wrapper-menu {
    bottom: 15px /* 21 change for delete txt*/;
    }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
}

