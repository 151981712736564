
.book div {
    background-color: #F7F7F7 !important;
    font-size: 15px !important;
    color: #222 !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
}

.btn-back {
    background-color: transparent;
	border: none;
	color: #2A426D;
    font-weight: bold;
    margin-bottom: 1em;
}

.btn-back:hover {
    color: #5A93B6;
}


.pdf div {    
    font-size: 15px !important;
    color: #222 !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    text-align: center;
}