.wrapper-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

/****************************/


.main-footer-students {
  padding: 1em ;
  text-decoration: none;
  color: #FFF;
  background-color: #2C3140;
  font-size: 13px; 

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;

    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.footer-languages_languages-students {
  margin: 0;
  padding: 0;
}

.footer-languages_languages-students li {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
}


.footer-languages_languages-students li a {
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
}

.footer-languages_languages-students li a:hover {
    text-decoration: none;
}

.col-right--copyright .red-link {
  color: #EF1A1A
}

.col-right--copyright i {
  display: none;
}

.col-right--copyright a {
  cursor: pointer;
}



/* MQ 750px */

@media screen and (max-width: 750px) {
  .col-right--copyright, .col-left--footer-laguages {
      font-size: 12px;
  }


}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .footer-languages_languages-students span, .footer-languages_languages-students .fas { display: none;}
  .footer-languages_languages-students { text-transform: uppercase;}
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .col-right--copyright .txt-copyright {
    display: none;
  }

  .col-right--copyright i {
    display: inline-block;
    padding: 0 0.5em;
  }
}
