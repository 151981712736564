.wrapper-home-matter {
  padding: 1em;
  background-color: #FFF;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
-moz-box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
  box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
  margin-bottom: 1.5em;
}

.wrapper-home-matter h1 {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}



/******/

.content-item-matter {
  margin-top: 0.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
        align-items: stretch;
}
.home-matter-select {
  background-color: #F1F1F1;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
      
}