.container-message {
    width: 100%;
    text-align: center;
}

.icon-message-test {
   color: #80C968;
   font-size: 80px; 
}

