.wrapper-iframe {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  position: relative;
}

.wrapper-iframe p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 1.5em;
}

.wrapper-iframe b {
  border-bottom: 1px solid #2C3140;
  font-size: 20px;
  display: block;
}

.wrapper-iframe video {
  width: 100% !important;
  height: auto;
  margin: 0.5em 0 !important;
}

.wrapper-iframe h1, .wrapper-iframe h2, .wrapper-iframe h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe h1, 
.wrapper-iframe h2,
.wrapper-iframe h3,
.wrapper-iframe h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}


.wrapper-iframe ul {
  margin: 2em 0;
}

.wrapper-iframe li {
  margin-bottom: 0.5em;
}

.wrapper-iframe a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe a:hover {
  text-decoration: none;
}


.wrapper-iframe table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #F7F7F7;
}

/* Zebra striping */
.wrapper-iframe tr:nth-of-type(odd) { 
  background: #FFF; 
}

.wrapper-iframe th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe td, .wrapper-iframe th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe img {
  display: block;
  margin: 1em 0;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe {
    margin: -35px 0 0 0;
  }
}

/* IFRAME KIDS */
.wrapper-iframe-kids {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  font-family: 'Quicksand', sans-serif;
}

.wrapper-iframe-kids video {
  width: 100% !important;
  height: 400px;
  margin: 0.5em 0 !important;
}

.wrapper-iframe-kids p {
  line-height: 1.6em;
  font-size: 20px;
  margin-bottom: 1.5em;
}

.wrapper-iframe-kids h1, .wrapper-iframe-kids h2, .wrapper-iframe-kids h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-kids h1, 
.wrapper-iframe-kids h2,
.wrapper-iframe-kids h3,
.wrapper-iframe-kids h4 {
  color: #40539d;
  margin: 1em 0 1.5em 0;
}

.wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
  background-color: #e8eff3;
  font-size: 18px;
  padding: 0.5em;
  color: #7982a9;
}

.wrapper-iframe-kids h2 {
  font-size: 22px;
}

.wrapper-iframe-kids h3 {
  font-size: 20px;
}

.wrapper-iframe-kids ul {
  margin: 2em 0;
  font-size: 20px;
}

.wrapper-iframe-kids li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-kids a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-kids a:hover {
  text-decoration: none;
}


.wrapper-iframe-kids table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #FFF;
  font-size: 20px;
}

.wrapper-iframe-kids th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe-kids td, .wrapper-iframe-kids th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe-kids img {
  display: block;
  margin: 1em 0;
}



.wrapper-iframe .lesson-title-subject {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #27466D;
}

.lesson-title-subject img {
  display: inline-block;
  width: 35px;
  vertical-align: middle;
  margin: 0;
  padding-right: 10px;
  margin: 0 !important;
}

.lesson-title-subject {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
}

.lesson-title-subject__left, .lesson-title-subject__right {
  vertical-align: middle;
}


.lesson-title-subject .lesson-title-subject__lesson {
  padding-left: 0.8em;
}

.lesson-title-subject .lesson-title-subject__subject {
  font-weight: bold;
}

.vviYD {
  padding: 0 !important;
}

.only-image-inline img {
  display: inline-block !important;
  vertical-align: middle !important;
   padding: 0 0.8em;
}

/* .wrapper-iframe h4.vviYD,
.wrapper-iframe-kids h4.vviYD {
  margin-bottom: 0;
} */

.wrapper-iframe-kids .vviYD,
.wrapper-iframe-kids--FullWidth .vviYD {
  padding: 0.7em !important;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe-kids {
      margin: -35px 0 0 0;
  }
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe-kids {
    padding: 0 0.5em 0.5em 0.5em;
  }

  .wrapper-iframe-kids p, .wrapper-iframe-kids table,
  .wrapper-iframe-kids ul { font-size: 18px; }

  .wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
    font-size: 15px;
  }
}