.wrapper-pareo {
  width: 80%;
  max-width: 670px;
  margin: 0 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-pareo p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0.5em 0;
}

/* .wrapper-pareo h1 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;
  color: #40539D;
  margin: 1em 0 1.5em 0;    
} */


.content-pareo {
  margin-bottom: 1em;
  display: -webkit-box; 
      display: -webkit-flex;  
      display: -ms-flexbox; 
      display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}

.content-pareo .pareo-col-left {
background-color: #F7F7F7;
width: 50%;
}

.content-pareo .pareo-col-right {
background-color: #E6EBFF;
width: 45%;
}

.pareo-col-left .list-form, .pareo-col-right .list-question {
list-style: none;
margin: 0;
padding: 0
}

.pareo-col-left .list-form li {
padding: 1em 1em 0.25em 1em;
}

.pareo-col-right .list-question li {
  padding: 0.25em 1em;
}

.pareo-col-right .list-question li span {
  font-weight: bold;
  display: inline-block;
}

.pareo-col-right .list-question li span p {
  margin: 0;
}


.pareo-col-left .list-form span {
padding-left: 0.5em;
display: inline-block;
}

.pareo-col-left .list-form span p {
  margin: 0;
  }




.list-form input {
  max-width: 12%;
  border: 0;
  padding: 5px;
  background: #F7F7F7 no-repeat;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  font-size: 18px;
  
  background-image: linear-gradient(to bottom, #7C89BF, #40539D), linear-gradient(to bottom, #DDD, #DDD);
  /* sizes for the 2 images (default state) */
  background-size: 0 2px, 100% 1px;
  /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
  background-position: 50% 100%, 50% 100%;
  /* animation solely on background-size */
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.list-form input:focus {
/* sizes for the 2 images (focus state) */
  background-size: 100% 2px, 100% 1px;
  outline: none;
}

.list-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
font-style: italic;
font-size: 16px;
opacity: 1; /* Firefox */
}

.list-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
font-size: 16px;
font-style: italic;
}

.list-form input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
font-size: 16px;
font-style: italic;
}


/* MQ 750px */

@media screen and (max-width: 750px) { 
  .content-pareo .pareo-col-left {
    width: 100%;
  }

.content-pareo .pareo-col-right {
    width: 100%;
    margin-top: 1.5em;
  }
}
