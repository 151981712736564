.wrapper-list-w-icon {
  width: 100%;
}

/*****************/

.list-w-icon {
border-bottom: 1px solid #DDD;
padding: 0.5em 1em;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 14px !important; 


display: -webkit-box;
display: -ms-flexbox;
display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;

}

.list-w-icon a {
color: #27466C;
font-weight: bold;
text-decoration: none;
font-size: 15px !important; 
}

.list-w-icon a:hover {
color: #5A93B6;
}

.list-w-icon__right {
  width: 130px;
}

.list-w-icon__right img {
  padding: 0.5em;
}

.list-w-icon__left {}

.list-w-icon__left p {
margin: 0.25em 0;
line-height: 1.3em;
}

.list-w-icon__txt-ppal {
  font-size: 16px !important;
  font-weight: bold;
}

.bg-list-offline {
  display: block;
  margin: 1em auto;
  width: 100%;
  text-align: center;
}

.bg-list-offline img {
  width: 100%;
  max-width: 390px;
  margin: auto;
}

/* MQ 500px */

@media screen and (max-width: 500px) {
.list-w-icon {
   -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.list-w-icon__right {  display: block; }

.list-w-icon__left { width: 100%; }
}

/* MQ 450px */

@media screen and (max-width: 450px) {
.list-w-icon__txt-ppal {
  font-size: 14px;
}

.list-w-icon { font-size: 12px; }
}
