.total-wrapper_lesson-progress {
  background-color: #F7F7F7;
  padding: 1.5em 1.5em 5em 1.5em;
}

.total-wrapper_lesson-progress h3 {
  font-weight: 800;
  font-size: 16px;
  margin: 0 0 0.25em 0;
}

.total-wrapper_lesson-progress h4 {
  font-weight: 600;
  font-size: 11px;
  margin: 0 0 2em 0;
}

/* TO MAKE SCROLL ON LESSON, ONLY LESSON */
.total-wrapper_lesson-progress .wrapper-lessons-progress {
  height: 450px;
  overflow-y: scroll;
}

.wrapper-lessons-progress::-webkit-scrollbar-track {	  
	border-radius: 10px;
  background-color: #FFF;
  border-left: 1px solid #E6E6E6;
  -moz-appearance: none !important;
}
  
.wrapper-lessons-progress::-webkit-scrollbar {
	width: 10px;
  background-color: #FFF;
  -moz-appearance: none !important;
}
  
.wrapper-lessons-progress::-webkit-scrollbar-thumb {
	border-radius: 10px;	 
  background-color: #D4D5D6;
  -moz-appearance: none !important;
}

@media screen and (max-width: 450px) {
      
  .total-wrapper_lesson-progress {
    padding: 1.5em 0.5em 5em 0.5em;
  }
}