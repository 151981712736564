/* TABLE */

table.table-message {
  width: 100%;
  border-collapse: collapse;
  background-color: #F4F4F4;
}

.table-message td {
  white-space: nowrap;
  padding: 1.5rem .75rem;
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
    cursor: pointer;
}

table.table-message tr:hover {
  /* -webkit-box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1);
-moz-box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1);
  box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1); */

  /* -webkit-box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1);
-moz-box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1);
box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1); */

-webkit-box-shadow: 0 8px 13px -3px rgba(0,0,0,0.1);
-moz-box-shadow: 0 8px 13px -3px rgba(0,0,0,0.1);
box-shadow: 0 8px 13px -3px rgba(0,0,0,0.1);
border-bottom: 2px solid #FFF;
} 



.td-truncate {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 50px;
}

.message-tabs .message-date {
  color: #9098AC;
  font-size: 12px;
}

.button-message-tabs {
    font-weight: bold;
    display: block;
    border: 0;
    background-color: transparent;
    font-size: 11px;
    text-transform: uppercase;
    color: #F2AF2B;
    cursor: pointer;
}

/* .button-message-tabs:hover {
  color: #27466C;
} */

.button-message-tabs .fas {
  font-size: 22px;
  padding: 0 0.25em
}

.button-message-tabs p {
  margin: 0.25em;
}

/* BUTTONS PRINCIPAL */

.message-content-buttons-mail {
  text-align: right;
  width: 100%;
  padding-bottom: 1em;
  border-bottom: 2px solid #F1F1F1;
    /* -webkit-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
  box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1); */
}

.message-content-buttons-mail button {
  margin: 0.5em;
}

.button-primary, .button-secondary {
  border-radius: 5px;
  padding: 0.8em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
}

.button-primary .fas, .button-secondary .fas {
  font-size: 14px;
  margin-right: 0.5em;
}

.button-primary {
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
}

.button-secondary {
  background-color: #27466C;
  border: 1px solid #27466C;
}

.button-primary:hover {
  background-color: #B3BCDF;
  border: 1px solid #B3BCDF;
}

.button-secondary:hover {
  background-color: #5A93B6;
  border: 1px solid #5A93B6;
}

.button-primary:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}




/* MQ 500 */

@media screen and (max-width: 500px) { 
  .clear, .button-message-tabs p {display: none;}
  .message-tabs label .fas { font-size: 28px; }
  .number-no-read { display: block; padding-top: 0.5em; }
}

